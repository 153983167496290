.modal.fade.CreateWebsite {
  display: block;
  opacity: 1;
}
.CreateWebsite__modal-dialog {
  padding: 60px 0;
}

.CreateWebsite__logos {
  list-style: none;
  padding: 0;
}
.CreateWebsite {
  height: 95vh;
}

.CreateWebsite__overflow-container {
  overflow-y: scroll;
}

.CreateWebsite__controllers {
  position: fixed;
  bottom: 0;
  left: 0;
}
