.TrackOverview__switcher-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  user-select: none;
  background-color: white;
  border: 1px solid grey;
  border-radius: inherit;
  z-index: 1;
  cursor: pointer;
}
.ModalForm__switcher-marker {
  position: relative;
  background-color: black;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  z-index: 2;
  pointer-events: none;
  box-shadow: var(--buttonGreenShadow);
}
