.DeleteConfirmation__modal {
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DeleteConfirmation__controllers {
  justify-content: space-around;
}
.DeleteConfirmation__controllers > * {
  width: 70px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}
.DeleteConfirmation__confirm {
  margin-bottom: 40px;
}
