.NewGoods {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NewGoods__fields {
  display: flex;
}
.NewGoods__container {
  width: 50%;

  &:first-child {
    margin-right: 20px;
  }
}

.NewGoods__sum-container {
  display: flex;
}

.NewGoods__quantity {
  margin-right: 10px;
}
