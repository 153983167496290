.modal.fade.CreateDirection {
  display: block;
  opacity: 1;
}
.CreateDirection__modal-dialog {
  padding: 60px 0;
}
.CreateDirection__taxfree-item {
  width: 100%;
  &:not(:last-child) {
    margin-right: 20px;
  }
}
