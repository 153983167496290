.LogoThumb {
  margin-left: 25px;
}

.ImageFileInput {
  height: 50px;
}

.File__delete-image {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 20px !important;
  height: 25px !important;
  display: none !important;
}

.File__image:hover .File__delete-image {
  display: block !important;
}
