.BlockButton__active {
  position: relative;
  left: 135%;
  pointer-events: none;
}
.BlockButton__blocked {
  position: relative;
  left: 35%;
  pointer-events: none;
}
